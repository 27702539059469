<template>
	<div>
		<div class="sctter-map-container">
			<div class="title">
				{{ title }}
			</div>
			<div class="itemList">
				<div class="item" :key="item.label" v-for="item in items">
					<div class="nameWrapper">{{ item.label }} :</div>
					<span class="num">{{ item.value }}</span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ['title', 'items'],
};
</script>
<style lang="scss">
.sctter-map-container {
	width: 300px;
	height: 130px;
	background: url('../assets/imgs/modal-bg.jpg');
	background-repeat: no-repeat;
	background-size: 100% 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	.title {
		display: flex;
		justify-content: center;
		font-size: 16px;
		margin: 6px 12px;
		color: #cceeff;
	}
	.itemList {
		width: 100%;
		height: 100%;
		display: flex;
		flex-wrap: wrap;
		padding: 12px 12px;
		gap: 8px;
		.item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: calc(50% - 4px);
			.nameWrapper {
				display: flex;
				align-items: center;
				font-size: 15px;
				color: rgba(255, 255, 255, 0.8);
			}
			.num {
				color: #0bf9fe;
				font-size: 15px;
			}
		}
	}
}
</style>
