<template>
	<div>
		<div class="m_xremin" v-if="type == 7">
			<div
				class="teamClass"
				v-for="(item, index) in items"
				:key="index"
				:style="[
					{ borderBottom: index === items.length - 1 ? 'none' : '1px solid #204e87' },
					{ paddingBottom: index === items.length - 1 ? '0' : '10px' },
					{ marginBottom: index === items.length - 1 ? '0' : '10px' },
				]"
			>
				<h3>{{ item.title }}</h3>
				<div class="teamClass-item m_xrows">
					<p>
						<span>团队人数：</span>
						<em>{{ (item.medicalWorkerGroup && item.medicalWorkerGroup.description) || 0 }}</em>
					</p>
					<p>
						<span>工作类型：</span>
						<em style="cursor: pointer" @click="checkWorkType(item)">{{ item.workName }}</em>
					</p>
				</div>
			</div>
			<img src="../assets/imgs/bg_1.png" class="up" style="left: -1px" />
			<img src="../assets/imgs/bg_2.png" class="up" style="right: -1px" />
			<img src="../assets/imgs/bg_3.png" class="bg" style="left: -1px" />
			<img src="../assets/imgs/bg_4.png" class="bg" style="right: -1px" />
		</div>
		<div class="m_xremin" v-else>
			<h3>{{ title }}</h3>
			<div class="m_xrows">
				<p :key="item.label" v-for="item in items">
					{{ item.label }}
					<!-- <em style="cursor: pointer;" onclick="window.usFun()" v-if="item.label=='医护人员数：'">{{ item.value }}</em> -->
					<em>{{ item.value }}</em>
				</p>
				<div></div>
			</div>
			<img src="../assets/imgs/bg_1.png" class="up" style="left: -1px" />
			<img src="../assets/imgs/bg_2.png" class="up" style="right: -1px" />
			<img src="../assets/imgs/bg_3.png" class="bg" style="left: -1px" />
			<img src="../assets/imgs/bg_4.png" class="bg" style="right: -1px" />
			<div class="button_doctor" @click="usFun()">在线联系</div>
		</div>
	</div>
</template>
<script>
import Vue from 'vue';
import { random } from '@/utils';
export default {
	props: ['title', 'items', 'type', 'data'],
	watch: {},
	mounted() {
		console.log(this.items, 'items');
		window.usFun = this.usFun;
		Vue.prototype.usFun = this.usFun;
	},
	methods: {
		random,
		usFun() {
			this.bus.$emit('show_doctor', this.type, this.data);
		},
		// 点击工作类型
		checkWorkType(item) {
			this.bus.$emit('show_visitAndDispatch', item);
		},
	},
};
</script>
<style lang="scss">
.m_xremin {
	background: rgba(5, 41, 92, 0.9);
	border: 1px solid #204e87;
	position: relative;
	width: 330px;
	padding: 15px;
}
.m_xremin h3 {
	font-size: 16px;
	color: #fff;
}
.m_xremin .bg {
	position: absolute;
	bottom: -1px;
}
.m_xremin .up {
	position: absolute;
	top: -1px;
}
.teamClass {
	&-item {
		justify-content: space-between;
	}
}
.m_xrows {
	display: flex;
	flex-wrap: wrap;
	p {
		font-size: 12px;
		color: #fff;
		margin: 10px 22px 0 0;
		position: relative;
	}
	em {
		color: #00ffff;
		font-style: normal;
	}
}
.m_xrows p:not(:nth-child(1))::before {
	position: absolute;
	content: '';
	left: -11px;
	width: 1px;
	height: 10px;
	top: 50%;
	margin-top: -5px;
	background: #204e87;
}
.m_xrows p:nth-child(3) {
	margin-right: 0;
}
.m_xrows p:nth-child(4)::before {
	width: 0;
}
.button_doctor {
	width: 60px;
	height: 25px;
	text-align: center;
	line-height: 25px;
	font-size: 12px;
	color: #fff;
	margin-left: 230px;
	margin-top: 10px;
	// position: absolute;
	right: 10px;
	bottom: 3px;
	border: #00ffff 1px solid;
	cursor: pointer;
}
</style>