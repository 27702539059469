<template>
	<div
		class="echarts"
		v-loading="loading"
		element-loading-text="加载中..."
		element-loading-background="rgba(0, 0, 0, 0.4)"
		:style="{ height: height + 'px' }"
	>
		<div v-show="!drillAMapData.visible" style="width: 100%; height: 100%" ref="sctterMap"></div>
		<drillAMap v-show="drillAMapData.visible" :centerPoint="drillAMapData.centerPoint" :mapType="mapType" :name="drillAMapData.name"></drillAMap>
		<div class="title">
			{{ addressInfo.name === '全国' ? '全国' : addressInfo.name }}
			<img v-if="addressInfo.name !== '全国'" class="titleUnderline" src="../views/homePage/assets/title_underline.jpg" />
		</div>
		<img ref="mapTexture" v-show="false" src="../assets/imgs/map-texture.jpg" />
	</div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapMutations } from 'vuex';
import * as echarts from 'echarts';
import mapTooltip from './mapTooltip.vue';
import { genPointInPolygen } from '@/utils/index';
import { randomByLevel } from '@/views/homePage/common';
import drillAMap from './DrillAMap.vue';
import '@/assets/icon-font/PangMenZhengDao.css';

export const countryLevelDataMap = {
	0: {
		name: '县级医院',
		img: require('!!url-loader!../views/homePage/assets/县级医院_map.png'),
		legend: require('!!url-loader!../views/homePage/assets/县级医院.png'),
	},
	1: {
		name: '乡卫生院',
		img: require('!!url-loader!../views/homePage/assets/乡卫生院_map.png'),
		legend: require('!!url-loader!../views/homePage/assets/乡卫生院.png'),
	},
	2: {
		name: '社区服务中心',
		img: require('!!url-loader!../views/homePage/assets/社区服务中心_map.png'),
		legend: require('!!url-loader!../views/homePage/assets/社区服务中心.png'),
	},
	3: {
		name: '村卫生室',
		img: require('!!url-loader!../views/homePage/assets/村卫生室_map.png'),
		legend: require('!!url-loader!../views/homePage/assets/村卫生室.png'),
	},
	4: {
		name: '诊所',
		img: require('!!url-loader!../views/homePage/assets/诊所_map.png'),
		legend: require('!!url-loader!../views/homePage/assets/诊所.png'),
	},
};
export const visitAndDispatchDataMap = {
	0: {
		name: '巡诊团队数',
		img: require('!!url-loader!../views/homePage/assets/巡诊团队_map.png'),
		legend: require('!!url-loader!../views/homePage/assets/巡诊团队.png'),
	},
	1: {
		name: '驻派团队数',
		img: require('!!url-loader!../views/homePage/assets/驻派团队_map.png'),
		legend: require('!!url-loader!../views/homePage/assets/驻派团队.png'),
	},
	2: {
		name: '医护人员数',
		img: require('!!url-loader!../views/homePage/assets/医护人员_map.png'),
		legend: require('!!url-loader!../views/homePage/assets/医护人员.png'),
	},
	3: {
		name: '服务人数',
		img: require('!!url-loader!../views/homePage/assets/服务人员_map.png'),
		legend: require('!!url-loader!../views/homePage/assets/服务人员.png'),
	},
	4: {
		name: '开展乡镇数',
		img: require('!!url-loader!../views/homePage/assets/乡镇_map.png'),
		legend: require('!!url-loader!../views/homePage/assets/乡镇.png'),
	},
};

export const doctorDataMap = {
	0: {
		name: '家庭医生团队',
		img: require('!!url-loader!../views/homePage/assets/驻派团队_map.png'),
		legend: require('!!url-loader!../views/homePage/assets/驻派团队.png'),
	},
};

export const MapType = {
	overview: 1,
	treatment: 2,
	doctor: 3,
	medic: 4,
	public: 5,
	visitAndDispatch: 7,
};

export default {
	name: 'sctterMap',
	components: { drillAMap },
	data() {
		return {
			loading: false,
			geoJson: {
				features: [],
			},
			countyLevelData: [],
			drillAMapData: {
				visible: false,
				centerPoint: [],
				name: '',
			},
			visitAndDispatchTooltipData: {}, //巡诊驻派tooltip数据
		};
	},
	props: {
		height: {
			type: Number,
			default: 200,
		},
		mapType: {
			type: Number,
			defualt: 1,
		},
	},
	computed: {
		...mapState('mapData', ['mapDataArr', 'addressInfo', 'mapLevel', 'parentInfo']),
		tooltipData() {
			return this.getMapToolTipByType(this.mapType, this.mapLevel);
		},
	},
	mounted() {
		let cityCode =
			JSON.parse(sessionStorage.getItem('userInfo')).regionCode != '0' ? JSON.parse(sessionStorage.getItem('userInfo')).regionCode : 100000;
		let chinaRegionDtoList = JSON.parse(sessionStorage.getItem('userInfo')).chinaRegionDtoList;
		let name = '全国';
		if (chinaRegionDtoList && chinaRegionDtoList.length > 0) {
			name = chinaRegionDtoList.map((v) => v.title).join('-');
		}
		let addressInfo = {
			cityCode: cityCode,
			name: name,
			regionCode: cityCode == 100000 ? null : cityCode,
		};
		let level = cityCode == 100000 ? 0 : cityCode.length == 2 ? 1 : cityCode.length == 4 ? 2 : cityCode.length == 6 ? 3 : 4;
		this.$store.dispatch('mapData/setAddressInfo', addressInfo);
		this.getBaseData(cityCode);
		this.getMapLevel(level);
		this.getParentInfo([addressInfo]);
		this.getVisitAndDispatchTooltipData(cityCode);
	},
	watch: {
		addressInfo: {
			handler(val) {
				this.getVisitAndDispatchTooltipData(val.regionCode);
			},
			deep: true,
		},
	},
	methods: {
		...mapMutations('mapData', ['getMapLevel', 'getParentInfo']),
		// 获取巡诊驻派tooltip数据
		async getVisitAndDispatchTooltipData(code) {
			if (code && code.length == 6 && code != 100000) {
				let res = await this.$http.get(this.api['TourMapSummaries#index'].href, { params: { regionCode: code } });
				if (res.data && res.data.success) {
					const { collection } = res.data;
					this.visitAndDispatchTooltipData = collection[0] || {};
				}
			}
		},
		getMapToolTipByType(mapType, level) {
			switch (mapType) {
				case MapType.overview:
					return [
						{ label: '县级医院', value: randomByLevel(level) },
						{ label: '社区服务中心', value: randomByLevel(level) },
						{ label: '乡卫生院', value: randomByLevel(level) },
						{ label: '诊所', value: randomByLevel(level) },
						{ label: '村卫生室', value: randomByLevel(level) },
					];
				case MapType.visitAndDispatch:
					return [
						{ label: '巡诊团队数', value: this.visitAndDispatchTooltipData.tourTeamCount || 0 },
						{ label: '驻派团队数', value: this.visitAndDispatchTooltipData.stationedTeamCount || 0 },
						{ label: '医护人员数', value: this.visitAndDispatchTooltipData.medicalStaffCount || 0 },
						{ label: '服务人数', value: this.visitAndDispatchTooltipData.servicePersonCount || 0 },
						{ label: '开展乡镇数', value: this.visitAndDispatchTooltipData.townCount || 0 },
					];
				case MapType.doctor:
				case MapType.medic:
					return [
						{ label: '家庭医生团队数', value: randomByLevel(level) },
						{ label: '医护人员数', value: randomByLevel(level) },
						{ label: '服务社区数', value: randomByLevel(level) },
						{ label: '服务人群数', value: randomByLevel(level) },
					];
				case MapType.treatment:
				case MapType.public:
					return [
						{ label: '医护人员总数', value: randomByLevel(level) },
						{ label: '慢性病患者数', value: randomByLevel(level) },
					];
			}
		},
		async getBaseData(cityCode) {
			this.loading = true;
			const res = await this.$store.dispatch('mapData/setBaseData');
			if (res.success) {
				await this.getGeoJson(cityCode);
				this.getMapData(cityCode);
				this.loading = false;
			}
		},
		getGeoJson(adcode) {
			let that = this;
			return new Promise((resolve) => {
				if (adcode.length > 6) return;
				switch (adcode) {
					case '110100':
						adcode = '110000';
						break;
					case '120100':
						adcode = '120000';
						break;
					case '310100':
						adcode = '310000';
						break;
					case '500100':
						adcode = '500000';
						break;
					case '500200':
						adcode = '500000';
						break;

					default:
						break;
				}
				AMapUI.loadUI(['geo/DistrictExplorer'], (DistrictExplorer) => {
					var districtExplorer = new DistrictExplorer();
					districtExplorer.loadAreaNode(adcode, function (error, areaNode) {
						if (error) {
							console.error(error);
							return;
						}

						let json = areaNode.getSubFeatures();
						if (json.length > 0) {
							that.geoJson.features = json;
							resolve();
						} else if (json.length === 0) {
							that.geoJson.features = [areaNode.getParentFeature()];
							resolve();
						}
					});
				});
			});
		},
		canGoback() {
			return this.drillAMapData.visible;
		},
		toBackPre(pop) {
			if (this.drillAMapData.visible) {
				this.drillAMapData.visible = false;
				return;
			}
			if (pop !== false) {
				this.parentInfo.pop();
				this.getMapLevel(this.mapLevel - 1);
			}
			const data = this.parentInfo[this.parentInfo.length - 1];
			this.$store.dispatch('mapData/setAddressInfo', { cityCode: data.cityCode, name: data.name, regionCode: data.regionCode });
			this.getBaseData(data.cityCode);
		},
		getMapData(cityCode) {
			this.countyLevelData = [];
			let mapData = this.mapDataArr.map((item) => {
				if (this.mapLevel < 3) {
					let mapJson = this.geoJson.features.find((ite) => {
						return item.regionName.includes(ite.properties.name.substring(0, 2));
					});

					return {
						...item,
						name: mapJson?.properties.name || '',
						value: [mapJson?.properties.center[0], mapJson?.properties.center[1], 5] || [],
						cityCode: mapJson?.properties.adcode || '',
					};
				} else {
					//街道的时候，需要整合所有街道到一个整体在地图上面显示
					item.medicalOrganizations += item.medicalOrganizations || 0;
					item.medicalWorkers += item.medicalWorkers || 0;
					item.circuitDiagnosisGroups += item.circuitDiagnosisGroups || 0;
					item.vehicles += item.vehicles || 0;
					item.residencyGroups += item.residencyGroups || 0;
					item.devices += item.devices || 0;
					item.name = this.geoJson.features[0].properties.name;
					item.value = [this.geoJson.features[0].properties.center[0], this.geoJson.features[0].properties.center[1], 5];
					item.cityCode = this.geoJson.features[0].properties.adcode;
					return item;
				}
			});
			if (this.mapLevel >= 3) {
				if (this.mapType == 7) {
					this.countyLevelData = Object.keys(visitAndDispatchDataMap).map((k, i) => {
						const { tourTeamCount, stationedTeamCount, medicalStaffCount, servicePersonCount, townCount } = this.visitAndDispatchTooltipData;
						const newData = {};
						newData.name = visitAndDispatchDataMap[Number(i)].name;
						newData.type = Number(k);
						newData.img = 'image://' + visitAndDispatchDataMap[Number(i)].img;
						if (newData.name == '巡诊团队数') {
							newData.value = [
								...genPointInPolygen(this.geoJson.features[0].properties.center, this.geoJson.features[0].geometry.coordinates[0]),
								tourTeamCount,
							];
						}
						if (newData.name == '驻派团队数') {
							newData.value = [
								...genPointInPolygen(this.geoJson.features[0].properties.center, this.geoJson.features[0].geometry.coordinates[0]),
								stationedTeamCount,
							];
						}
						if (newData.name == '医护人员数') {
							newData.value = [
								...genPointInPolygen(this.geoJson.features[0].properties.center, this.geoJson.features[0].geometry.coordinates[0]),
								medicalStaffCount,
							];
						}
						if (newData.name == '服务人数') {
							newData.value = [
								...genPointInPolygen(this.geoJson.features[0].properties.center, this.geoJson.features[0].geometry.coordinates[0]),
								servicePersonCount,
							];
						}
						if (newData.name == '开展乡镇数') {
							newData.value = [
								...genPointInPolygen(this.geoJson.features[0].properties.center, this.geoJson.features[0].geometry.coordinates[0]),
								townCount,
							];
						}
						return newData;
					});
				} else {
					this.countyLevelData = Object.keys(countryLevelDataMap).map((k, i) => {
						const newData = {};
						newData.name = countryLevelDataMap[Number(i)].name;
						newData.type = Number(k);
						newData.img = 'image://' + countryLevelDataMap[Number(i)].img;
						newData.value = [
							...genPointInPolygen(this.geoJson.features[0].properties.center, this.geoJson.features[0].geometry.coordinates[0]),
							randomByLevel(this.mapLevel),
						];
						return newData;
					});
				}
				mapData = [mapData[0]];
			}

			this.initEcharts(mapData, cityCode);
		},
		initEcharts(mapData, cityCode) {
			const that = this;
			const mapLevel = this.mapLevel;
			const zoom = cityCode === 100000 ? 1.5 : 1;
			this.myChart = echarts.init(this.$refs.sctterMap);
			echarts.registerMap('Map', this.geoJson);
			this.myChart.setOption(
				{
					grid: {
						top: '3%',
					},
					tooltip: {
						show: this.mapType,
						extraCssText: 'padding:0px;',
						formatter(item) {
							if (mapLevel === 3 && item.seriesType === 'scatter') {
								return null;
							}
							const MapTooltip = Vue.extend(mapTooltip);
							const v = new MapTooltip({
								propsData: { title: item.name, items: that.tooltipData },
							});
							v.$mount();
							return v.$el.innerHTML;
						},
					},
					toolbox: {
						feature: {
							restore: {
								show: false,
							},
							dataZoom: {
								show: false,
							},
							magicType: {
								show: false,
							},
						},
						iconStyle: {
							normal: {
								borderColor: '#1990DA',
							},
						},
						top: 15,
						right: 35,
					},
					legend: {
						orient: 'vertical',
						id: 1,
						bottom: 18,
						right: 20,
						itemWidth: 22,
						itemHeight: 24,
						data:
							this.mapType != 7
								? Object.keys(countryLevelDataMap).map((d) => {
										return { name: countryLevelDataMap[d].name, icon: 'image://' + countryLevelDataMap[d].legend };
								  })
								: Object.keys(visitAndDispatchDataMap).map((d) => {
										return { name: visitAndDispatchDataMap[d].name, icon: 'image://' + visitAndDispatchDataMap[d].legend };
								  }),
						textStyle: {
							color: '#fff',
						},
					},
					geo: {
						map: 'Map',
						type: 'map',
						roam: false,
						top: cityCode === 100000 ? 158 : 70,
						zoom,
						itemStyle: {
							normal: {
								areaColor: '#478CF2',
							},
							emphasis: {
								areaColor: '#478CF2',
							},
						},
					},
					series: [
						{
							name: '地图',
							type: 'map',
							map: 'Map',
							roam: false,
							zoom,
							data: mapData,
							showLegendSymbol: false,
							top: cityCode === 100000 ? 150 : 70,
							label: {
								normal: {
									show: true,
									color: 'rgb(249, 249, 249)', //省份标签字体颜色
									fontWeight: 'bolder',
									formatter: (p) => {
										switch (p.name) {
											case '内蒙古自治区':
												p.name = '内蒙古';
												break;
											case '西藏自治区':
												p.name = '西藏';
												break;
											case '新疆维吾尔自治区':
												p.name = '新疆';
												break;
											case '宁夏回族自治区':
												p.name = '宁夏';
												break;
											case '广西壮族自治区':
												p.name = '广西';
												break;
											case '香港特别行政区':
												p.name = '香港';
												break;
											case '澳门特别行政区':
												p.name = '澳门';
												break;
											default:
												break;
										}
										return p.name;
									},
								},
								emphasis: {
									show: this.mapLevel === 3 ? false : true,
									color: '#fff',
								},
							},
							itemStyle: {
								normal: {
									borderWidth: 1.3,
									borderColor: '#fff',
									areaColor: {
										image: this.$refs.mapTexture,
										repeat: 'no-repeat',
									},
								},
								emphasis:
									this.mapLevel === 3
										? {
												borderWidth: 1.3,
												borderColor: '#fff',
												areaColor: {
													image: this.$refs.mapTexture,
													repeat: 'no-repeat',
												},
										  }
										: {
												areaColor: '#D08229',
												borderColor: '#fff',
												borderWidth: 1.6,
												shadowBlur: 25,
										  },
							},
						},
						...this.countyLevelData.map((c, idx) => {
							return {
								name: c.name,
								type: 'scatter',
								coordinateSystem: 'geo',
								data: [this.countyLevelData[idx]],
								symbol: (value, param) => {
									return param.data.img;
								},
								itemStyle: {
									opacity: 1,
								},
								label: {
									show: true,
									formatter: function (d) {
										return d.value[2];
									},
									offset: [0, -40],
									color: '#fff',
									backgroundColor: 'rgba(0,0,0,0.6)',
									padding: [4, 6],
									fontSize: 15,
								},
								symbolSize: [30, 57],
							};
						}),
					],
				},
				true
			);
			this.myChart.off('click');
			this.myChart.on('click', (params) => {
				if (!params.data) {
					return;
				}
				if (params.data.name == '医护人员数' || params.data.name == '服务人数' || params.data.name == '开展乡镇数' || params.data?.value[2] == 0)
					return this.$message.error('当前点击数据没有下一级');
				if (this.mapLevel >= 3) {
					this.drillAMapData.visible = true;
					this.drillAMapData.centerPoint = this.geoJson.features[0].properties.center;
					this.drillAMapData.name = params.data.name;
					this.getMapLevel(this.mapLevel + 1);
					return;
				}
				let data = params.data;
				this.parentInfo.push({
					cityCode: data.cityCode,
					regionCode: data.regionCode,
					name: data.name,
				});
				this.getParentInfo(this.parentInfo.slice());
				this.getMapLevel(this.mapLevel + 1);
				this.$store.dispatch('mapData/setAddressInfo', { cityCode: data.cityCode, name: data.name, regionCode: data.regionCode });
				this.getBaseData(data.cityCode);
			});
		},
	},
};
</script>
<style lang="scss" scoped>
.echarts {
	width: 100%;
	height: calc(100% - 300px);
	position: relative;
	background: url('../assets/imgs/图层 814.png') no-repeat;
	background-size: 100% 100%;
}

.title {
	font-family: 'Microsoft YaHei';
	font-size: 22px;
	font-weight: bold;
	position: absolute;
	width: 100%;
	justify-content: center;
	text-align: center;
	top: 20px;
	color: #fff;
	line-height: 42px;
	.titleUnderline {
		position: absolute;
		bottom: 8px;
		width: 138px;
		height: 6px;
		left: calc(50% - 69px);
	}
}
</style>
