<template>
	<div :style="{ height: size.h, width: size.w, transformOrigin: '0 0', transform: `scale(${mapScale})` }" id="drillAMap"></div>
</template>
<script>
import { mapState } from 'vuex';
import { countryLevelDataMap, visitAndDispatchDataMap, doctorDataMap } from './sctterMap.vue';
import { genRandomPointByCenter, getHealthName } from '@/utils/index';
import InfoWindowContent from '@/components/mapAreaTooltip';
import Vue from 'vue';

export default {
	props: ['centerPoint', 'mapType', 'name'],
	computed: {
		mapScale() {
			return 1 / this.$store.state.homePage.scale;
		},
		size() {
			return { h: this.$store.state.homePage.scale * 100 + '%', w: this.$store.state.homePage.scale * 100 + '%' };
		},
		...mapState('mapData', ['mapLevel', 'mapDataArr', 'addressInfo']),
	},
	data() {
		return {
			visitData: [], //巡诊驻派数据
			visitAndDispatchPointData: [], //巡诊驻派点位数据
		};
	},
	watch: {
		async centerPoint() {
			if (this.cluster) {
				this.cluster.setMap(null);
			}
			this.map.setCenter(this.centerPoint);
			if (this.mapType === 3) {
				this.drawMarkerDoctor();
				return;
			}
			if (this.mapType === 7) {
				this.getVisitAndDispatchData();
				this.getVisitData();
				return;
			}
			const markers = [];
			let type = this.mapType; // 1-总览 2-慢病防治 3-家庭医生  7-巡诊驻派
			const typeArray =
				type != 7
					? Object.keys(countryLevelDataMap).map((k) => {
							return countryLevelDataMap[k];
					  })
					: Object.keys(visitAndDispatchDataMap).map((k) => {
							return visitAndDispatchDataMap[k];
					  });
			new Array(70)
				.fill(0)
				.map(() => {
					return { title: getHealthName(this.addressInfo.name), point: genRandomPointByCenter(this.centerPoint, 0.1) };
				})
				.map((d) => {
					const { point: p } = d;
					const item = typeArray.sort(() => 0.5 - Math.random())[0];
					const m = new AMap.Marker({
						position: p,
						icon: new AMap.Icon({
							image: item.img,
							size: new AMap.Size(30, 57),
							imageOffset: new AMap.Pixel(0, 0),
						}),
						extData: d,
					});
					m.on('click', (e) => {
						const t = e.target.getExtData().title;
						let arr = [];
						if (type == 1) {
							arr = [
								{ label: '机构名称：', value: 307 },
								{ label: '人管数量：', value: 1023 },
								{ label: '床位：', value: 900 },
							];
						} else if (type == 2) {
							arr = [
								{ label: '医护人员总数：', value: 5000 },
								{ label: '慢性病患者数：', value: 9000 },
							];
						} else if (type == 3) {
							arr = [
								{ label: '医生团队数：', value: 3 },
								{ label: '医护人员数：', value: 3 },
								{ label: '服务社区数：', value: 6 },
								{ label: '服务人群数：', value: 6 },
							];
						} else if (type == 7) {
							arr = this.visitData.map((v) => {
								return {
									...v,
									title: v.name,
									workName: v.discriminationCode == 'T' ? '巡诊' : '驻派',
								};
							});
						}
						const MapTooltip = Vue.extend(InfoWindowContent);
						const v =
							type != 7
								? new MapTooltip({
										propsData: {
											title: t,
											items: arr,
											type: type,
										},
								  })
								: new MapTooltip({
										propsData: {
											items: arr,
											type,
										},
								  });
						v.$mount();
						const infoWindow = new AMap.InfoWindow({
							closeWhenClickMap: true,
							isCustom: true,
							content: v.$el,
							anchor: 'bottom-center',
							offset: new AMap.Pixel(2, -26),
						});
						infoWindow.open(this.map, e.target.getPosition());
					});
					markers.push(m);
				});
			this.cluster = new AMap.MarkerClusterer(this.map, markers, {
				// styles: sts,
				gridSize: 80,
			});
		},
		name: {
			handler(val) {
				console.log(val, 'name');
			},
			deep: true,
		},
	},
	mounted() {
		this.map = new window.AMap.Map('drillAMap', {
			zoom: 13,
			center: [116.39, 39.92],
			resizeEnable: true,
			mapStyle: 'amap://styles/darkblue',
		});
		this.getVisitData();
	},
	methods: {
		async drawMarkerDoctor() {
			const healthGroup = await this.$http.get('/management-api/v1/HealthServiceGroups.json', {
				params: { mapCode: this.addressInfo.regionCode, size: 9999 },
			});
			const markers = [];
			healthGroup?.data?.collection
				?.map((d) => {
					return { id: d.id, title: d.name, point: [d.region.longitude, d.region.latitude] };
				})
				.map((d) => {
					const { point: p } = d;
					const m = new AMap.Marker({
						position: p,
						icon: new AMap.Icon({
							image: doctorDataMap[0].img,
							size: new AMap.Size(30, 57),
							imageOffset: new AMap.Pixel(0, 0),
						}),
						extData: d,
					});
					m.on('click', (e) => {
						const t = e.target.getExtData().title;
						let arr = [
							{ label: '医生团队数：', value: 3 },
							{ label: '医护人员数：', value: 3 },
							{ label: '服务社区数：', value: 6 },
							{ label: '服务人群数：', value: 6 },
						];

						const MapTooltip = Vue.extend(InfoWindowContent);
						const v = new MapTooltip({
							propsData: {
								title: t,
								items: arr,
								type: 3,
								data: d,
							},
						});
						v.$mount();
						const infoWindow = new AMap.InfoWindow({
							closeWhenClickMap: true,
							isCustom: true,
							content: v.$el,
							anchor: 'bottom-center',
							offset: new AMap.Pixel(2, -26),
						});
						infoWindow.open(this.map, e.target.getPosition());
					});
					markers.push(m);
				});
			this.cluster = new AMap.MarkerClusterer(this.map, markers, {
				gridSize: 80,
			});
		},
		// 获取巡诊驻派数据
		getVisitData() {
			this.$http
				.get(this.api['TourPlans#index'].href, {
					params: {
						villageCode: this.addressInfo.regionCode,
					},
				})
				.then((res) => {
					if (res.data && res.data.success) {
						const { collection } = res.data;
						this.visitData = collection;
					}
				});
		},
		// 获取区县下巡诊驻派数据
		async getVisitAndDispatchData() {
			console.log(this.addressInfo.regionCode, 'regionCode');
			let discriminationCode = this.name == '巡诊团队数' ? 'T' : 'D';
			let data = {
				discriminationCode,
				current: 1,
				size: 9999,
				regionCode: this.addressInfo.regionCode,
			};
			let res = await this.$http.get(this.api['MedicalWorkerGroups#index'].href, { params: data });
			if (res.data && res.data.success) {
				const { collection } = res.data;
				const markers = [];
				collection
					?.map((d) => {
						return { id: d.id, title: d.name, point: [d.regions[d.regions.length - 1].longitude, d.regions[d.regions.length - 1].latitude], ...d };
					})
					.map((d) => {
						const { point: p } = d;
						const m = new AMap.Marker({
							position: p,
							icon: new AMap.Icon({
								image: visitAndDispatchDataMap[0].img,
								size: new AMap.Size(30, 57),
								imageOffset: new AMap.Pixel(0, 0),
							}),
							extData: d,
						});
						m.on('click', (e) => {
							const t = e.target.getExtData().title;
							console.log(this.visitData, 'visitData');
							let arr = this.visitData.map((v) => {
								return {
									...v,
									title: v.name,
									workName: v.discriminationCode == 'T' ? '巡诊' : '驻派',
								};
							});
							const MapTooltip = Vue.extend(InfoWindowContent);
							const v = new MapTooltip({
								propsData: {
									items: arr,
									type: 7,
								},
							});
							v.$mount();
							const infoWindow = new AMap.InfoWindow({
								closeWhenClickMap: true,
								isCustom: true,
								content: v.$el,
								anchor: 'bottom-center',
								offset: new AMap.Pixel(2, -26),
							});
							infoWindow.open(this.map, e.target.getPosition());
						});
						markers.push(m);
					});
				this.cluster = new AMap.MarkerClusterer(this.map, markers, {
					gridSize: 80,
				});
			}
		},
	},
};
</script>
<style lang="scss" scoped>
#drillAMap {
	width: 100%;
	height: 100%;
}
</style>
